import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import BackgroundImage from "gatsby-background-image"

import FeaturedWork from "../components/FeaturedWork.component"

import featuredWorkStyles from "./featuredWorkStyles.module.scss"

function getImageFromSlug(imageList, slug) {
   return imageList.find(image => image.name === slug)
}

const FeaturedWorkPage = ({ transitionStatus, entry, exit }) => {
   const data = useStaticQuery(graphql`
      query {
         bgs: allFile(
            filter: { relativeDirectory: { eq: "featured-work/bgs" } }
         ) {
            nodes {
               childImageSharp {
                  fluid(quality: 60, maxWidth: 1200) {
                     ...GatsbyImageSharpFluid_withWebp
                  }
               }
               name
            }
         }
         allMdx(
            filter: { frontmatter: { type: { eq: "featuredWork" } } }
            sort: { fields: frontmatter___order }
         ) {
            nodes {
               id
               frontmatter {
                  genre
                  artist
                  slug
                  order
                  socials {
                     spotify
                     instagram
                     facebook
                     twitter
                     soundcloud
                     globe
                  }
                  spotifyEmbed
                  styles {
                     containerBackground {
                        image
                        color
                        align
                     }
                     contentBackground {
                        color
                     }
                     text {
                        headingColor
                        introColor
                        introAccent
                        mainColor
                        mainAccent
                     }
                  }
                  title
               }
               excerpt
               body
            }
         }
      }
   `)

   const animationStatus = {
      transitionStatus: transitionStatus,
      entry: entry,
      exit: exit,
   }

   const bgImages = data.bgs.nodes

   const featuredWorkData = data.allMdx.nodes

   featuredWorkData.map(fw => {
      return (fw.frontmatter.bgImage = getImageFromSlug(
         bgImages,
         fw.frontmatter.slug
      ))
   })

   const footerContent = {
      C2As: [
         {
            text: "Want to hear more? Check my full discography",
            link: "full-discography",
         },
         {
            text: "Interested in working with me? Get in touch",
            link: "contact",
         },
      ],
      styles: {
         backgroundColor: "#FEE191",
      },
   }

   return (
      <BackgroundImage
         Tag="div"
         className={"mainContainer backgroundImage"}
         fluid={featuredWorkData[0].frontmatter.bgImage.childImageSharp.fluid}
         backgroundColor={
            featuredWorkData[0].frontmatter.styles.containerBackground.color
         }
         preserveStackingContext={true}
         style={{ backgroundPosition: "top", backgroundSize: "100%" }}
      >
         <Layout
            title="Featured Work"
            superTitle="Mr BJ Jackson"
            pageStyles={featuredWorkStyles}
            animationStatus={animationStatus}
            footerContent={footerContent}
         >
            <h4 className={`${featuredWorkStyles.introduction} subheader`}>
               A selection of recent projects I’ve been working on.
            </h4>
            {featuredWorkData.map((work, index) => (
               <FeaturedWork work={work} key={work.id} index={index} />
            ))}
         </Layout>
      </BackgroundImage>
   )
}

export default FeaturedWorkPage
