import React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import featuredWorkStyles from "../pages/featuredWorkStyles.module.scss"

import BackgroundImage from "gatsby-background-image"

import styled from "styled-components"

import Fade from "react-reveal/Fade"

function pad(num, size) {
   let s = "000000000" + num + "."
   return s.substr(s.length - size - 1)
}

const featuredWork = ({ work, index }) => {
   const styles = work.frontmatter.styles

   const indexNumber = pad(index + 1, 2)

   const bgImage = work.frontmatter.bgImage.childImageSharp.fluid

   const FeaturedWorkContent = styled.div`
   background-color: ${styles.contentBackground.color};
   color:${styles.text.mainColor};

    ::before {
      content:'${indexNumber}';
      color:${styles.text.headingColor};
      position: absolute;
      top: -55px;
      left: -90px;
      font-size: 6rem;
      font-weight: bold;
      letter-spacing: -0.04em;

      @media screen and (max-width:1086px) {
        left:0;
        top:-65px;
      }
    }
  

   & h2 {
    color:${styles.text.headingColor};
   }

   & p {
     color:${styles.text.mainColor};
   }

   & p a, & p strong, & p em {
    color:${styles.text.mainAccent};
   }

   & .fwIntro p {
    color:${styles.text.introColor};
   }
   & .fwIntro p a, & .fwIntro p strong, & .fwIntro p em {
    color:${styles.text.introAccent};
   }

   & .socialLinks a {
    color:${styles.text.mainColor};
   }

   & .socialLinks a:hover {
    color:${styles.text.mainAccent};
   }


  `
   const showBG = index > 0

   return (
      <>
         {showBG ? (
            <BackgroundImage
               Tag="section"
               className="featuredWorkContainer"
               preserveStackingContext={true}
               fluid={bgImage}
               backgroundColor={
                  work.frontmatter.styles.containerBackground.color
               }
               style={{ backgroundPosition: "top", backgroundSize: "100%" }}
            >
               <div
                  className={`${featuredWorkStyles.featuredWorkCenterer} featuredWorkCenterer`}
               >
                  <FeaturedWorkContent
                     className={featuredWorkStyles.featuredWorkContent}
                  >
                     <Fade bottom>
                        <h3>{work.frontmatter.genre}</h3>
                     </Fade>
                     <h2>
                        <Fade bottom>{work.frontmatter.artist}</Fade>
                     </h2>
                     <Fade>
                        <div className={"fwInfo"}>
                           <MDXRenderer frontmatter={work.frontmatter}>
                              {work.body}
                           </MDXRenderer>
                        </div>
                     </Fade>
                  </FeaturedWorkContent>
               </div>
            </BackgroundImage>
         ) : (
            <section className="featuredWorkContainerFirst">
               <div
                  className={`${featuredWorkStyles.featuredWorkCenterer} featuredWorkCenterer`}
               >
                  <FeaturedWorkContent
                     className={featuredWorkStyles.featuredWorkContent}
                  >
                     <Fade bottom>
                        <h3>{work.frontmatter.genre}</h3>
                     </Fade>
                     <h2>
                        <Fade bottom>{work.frontmatter.artist}</Fade>
                     </h2>
                     <Fade>
                        <div className={"fwInfo"}>
                           <MDXRenderer frontmatter={work.frontmatter}>
                              {work.body}
                           </MDXRenderer>
                        </div>
                     </Fade>
                  </FeaturedWorkContent>
               </div>
            </section>
         )}
      </>
   )
}

export default featuredWork
